import React from "react";

import "./styles/Navbar.css";

const Navbar = (props) => {
  return (
    <div className="navbar">
      <a
        href="https://blog.pillowboy.me"
        rel="noreferrer"
        target="_blank"
        className="navbar-element"
      >
        <button>Blog </button>
      </a>

      <a
        href="https://github.com/poolsar42"
        rel="noreferrer"
        target="_blank"
        className="navbar-element"
      >
        <button> GitHub</button>
      </a>

      <div className="navbar-header">pillowboy</div>
    </div>
  );
};

export default Navbar;
