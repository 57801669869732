import React from "react";
import { Route, Routes } from "react-router-dom";
import App from "./App";
import "./styles/App.css";
import Navbar from "./Navbar";

const Switch = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<App />} />
      </Routes>
    </div>
  );
};

export default Switch;
