import "./styles/App.css";
import React from "react";
import Draggable from "react-draggable";

function App() {
  return (
    <>
      <div className="App">
        <div className="welcome">
          <Draggable>
            <div className="text-welcome">
              <p> Welcome </p>
            </div>
          </Draggable>
          <Draggable>
            <div
              className="App-avatar"
              style={{
                backgroundImage:
                  "url(https://avatars.githubusercontent.com/u/33105890?s=400&u=11b6423d96ee1896dba3f5dc42a1a85c0a755619&v=4)",
              }}
            />
          </Draggable>
        </div>
      </div>
      <div className="dashboard">
        <Draggable>
          <div className="text">
            <p>
              {" "}
              I often go by pillowboy on the Internet. Tho sometimes you may see
              me as pillowguy, poolsarman and even pillowuser.
            </p>
            <p>
              I am a functional programmer with love to distributed systems,
              math, biology and a little bit of physics. However sometimes I may
              spend a big amount of time digging in something different.
            </p>
          </div>
        </Draggable>
      </div>
    </>
  );
}

export default App;
