import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import history from "./history";
import { HashRouter as Router } from "react-router-dom";
import Switch from "./components/Routes";
ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
